import React from 'react'
import { PageProps } from 'gatsby'
import { siteMetadata } from '../../gatsby-config'

import Layout from '../components/Layout'

import PageSection from '../components/PageSection'
import SectionHeaderRow from '../components/SectionHeaderRow'
import Meta from '../components/Meta'

const Datenschutz: React.FC<PageProps> = ({ location }: PageProps) => {
  return (
    <Layout location={location}>
      <Meta site={siteMetadata} title="Datenschutz" />
      <PageSection>
        <SectionHeaderRow headline="Datenschutz" />
        <div className="row">
          <div className="col-12">
            <p>1. Datenschutz auf einen Blick</p>
            <p>Allgemeine Hinweise</p>
            <p>
              <span>
                Die folgenden Hinweise geben einen einfachen &Uuml;berblick
                dar&uuml;ber, was mit Ihren personenbezogenen Daten passiert,
                wenn Sie diese Website besuchen. Personenbezogene Daten sind
                alle Daten, mit denen Sie pers&ouml;nlich identifiziert werden
                k&ouml;nnen. Ausf&uuml;hrliche Informationen zum Thema
                Datenschutz entnehmen Sie unserer unter diesem Text
                aufgef&uuml;hrten Datenschutzerkl&auml;rung.
              </span>
            </p>

            <p>Datenerfassung auf dieser Website</p>
            <p>
              <span>
                Wer ist verantwortlich f&uuml;r die Datenerfassung auf dieser
                Website?
              </span>
            </p>

            <p>
              <span>
                Die Datenverarbeitung auf dieser Website erfolgt durch den
                Websitebetreiber. Dessen Kontaktdaten k&ouml;nnen Sie dem
                Impressum dieser Website entnehmen.
              </span>
            </p>

            <p>Wie erfassen wir Ihre Daten?</p>

            <p>
              <span>
                Ihre Daten werden zum einen dadurch erhoben, dass Sie uns diese
                mitteilen. Hierbei kann es sich z. B. um Daten handeln, die Sie
                in ein Kontaktformular eingeben.
              </span>
            </p>

            <p>
              <span>
                Andere Daten werden automatisch beim Besuch der Website durch
                unsere IT-Systeme erfasst. Das sind vor allem technische Daten
                (z. B. Internetbrowser, Betriebssystem oder Uhrzeit des
                Seitenaufrufs). Die Erfassung dieser Daten erfolgt automatisch,
                sobald Sie diese Website betreten.
              </span>
            </p>

            <p>Wof&uuml;r nutzen wir Ihre Daten?</p>

            <p>
              <span>
                Ein Teil der Daten wird erhoben, um eine fehlerfreie
                Bereitstellung der Website zu gew&auml;hrleisten. Andere Daten
                k&ouml;nnen zur Analyse Ihres Nutzerverhaltens verwendet werden.
              </span>
            </p>

            <p>
              <span>Welche Rechte haben Sie bez&uuml;glich Ihrer Daten?</span>
            </p>

            <p>
              <span>
                Sie haben jederzeit das Recht unentgeltlich Auskunft &uuml;ber
                Herkunft, Empf&auml;nger und Zweck Ihrer gespeicherten
                personenbezogenen Daten zu erhalten. Sie haben au&szlig;erdem
                ein Recht, die Berichtigung oder L&ouml;schung dieser Daten zu
                verlangen. Hierzu sowie zu weiteren Fragen zum Thema Datenschutz
                k&ouml;nnen Sie sich jederzeit unter der im Impressum
                angegebenen Adresse an uns wenden. Des Weiteren steht Ihnen ein
                Beschwerderecht bei der zust&auml;ndigen Aufsichtsbeh&ouml;rde
                zu.
              </span>
            </p>

            <p>
              <span>
                Au&szlig;erdem haben Sie das Recht, unter bestimmten
                Umst&auml;nden die Einschr&auml;nkung der Verarbeitung Ihrer
                personenbezogenen Daten zu verlangen. Details hierzu entnehmen
                Sie der Datenschutzerkl&auml;rung unter &bdquo;Recht auf
                Einschr&auml;nkung der Verarbeitung&ldquo;.
              </span>
            </p>

            <p>
              <span>Analyse-Tools und Tools von Drittanbietern</span>
            </p>
            <p>
              <span>
                Beim Besuch dieser Website kann Ihr Surf-Verhalten statistisch
                ausgewertet werden. Das geschieht vor allem mit Cookies und mit
                sogenannten Analyseprogrammen. Die Analyse Ihres Surf-Verhaltens
                erfolgt in der Regel anonym; das Surf-Verhalten kann nicht zu
                Ihnen zur&uuml;ckverfolgt werden.
              </span>
            </p>

            <p>
              <span>
                Sie k&ouml;nnen dieser Analyse widersprechen oder sie durch die
                Nichtbenutzung bestimmter Tools verhindern. Detaillierte
                Informationen zu diesen Tools und &uuml;ber Ihre
                Widerspruchsm&ouml;glichkeiten finden Sie in der folgenden
                Datenschutzerkl&auml;rung.
              </span>
            </p>

            <p>
              <span>2. Hosting und Content Delivery Networks (CDN)</span>
            </p>
            <p>Externes Hosting</p>
            <p>
              <span>
                Diese Website wird bei einem externen Dienstleister gehostet
                (Hoster). Die personenbezogenen Daten, die auf dieser Website
                erfasst werden, werden auf den Servern des Hosters gespeichert.
                Hierbei kann es sich v. a. um IP-Adressen, Kontaktanfragen,
                Meta- und Kommunikationsdaten, Vertragsdaten, Kontaktdaten,
                Namen, Webseitenzugriffe und sonstige Daten, die &uuml;ber eine
                Website generiert werden, handeln.
              </span>
            </p>

            <p>
              <span>
                Der Einsatz des Hosters erfolgt zum Zwecke der
                Vertragserf&uuml;llung gegen&uuml;ber unseren potenziellen und
                bestehenden Kunden (Art. 6 Abs. 1 lit. b DSGVO) und im Interesse
                einer sicheren, schnellen und effizienten Bereitstellung unseres
                Online-Angebots durch einen professionellen Anbieter (Art. 6
                Abs. 1 lit. f DSGVO).
              </span>
            </p>

            <p>
              <span>
                Unser Hoster wird Ihre Daten nur insoweit verarbeiten, wie dies
                zur Erf&uuml;llung seiner Leistungspflichten erforderlich ist
                und unsere Weisungen in Bezug auf diese Daten befolgen.
              </span>
            </p>

            <p>
              <span>
                Abschluss eines Vertrages &uuml;ber Auftragsverarbeitung
              </span>
            </p>

            <p>
              <span>
                Um die datenschutzkonforme Verarbeitung zu gew&auml;hrleisten,
                haben wir einen Vertrag &uuml;ber Auftragsverarbeitung mit
                unserem Hoster geschlossen.
              </span>
            </p>

            <p>
              <span>3. Allgemeine Hinweise und Pflichtinformationen</span>
            </p>
            <p>Datenschutz</p>
            <p>
              <span>
                Die Betreiber dieser Seiten nehmen den Schutz Ihrer
                pers&ouml;nlichen Daten sehr ernst. Wir behandeln Ihre
                personenbezogenen Daten vertraulich und entsprechend der
                gesetzlichen Datenschutzvorschriften sowie dieser
                Datenschutzerkl&auml;rung.
              </span>
            </p>

            <p>
              <span>
                Wenn Sie diese Website benutzen, werden verschiedene
                personenbezogene Daten erhoben. Personenbezogene Daten sind
                Daten, mit denen Sie pers&ouml;nlich identifiziert werden
                k&ouml;nnen. Die vorliegende Datenschutzerkl&auml;rung
                erl&auml;utert, welche Daten wir erheben und wof&uuml;r wir sie
                nutzen. Sie erl&auml;utert auch, wie und zu welchem Zweck das
                geschieht.
              </span>
            </p>

            <p>
              <span>
                Wir weisen darauf hin, dass die Daten&uuml;bertragung im
                Internet (z. B. bei der Kommunikation per E-Mail)
                Sicherheitsl&uuml;cken aufweisen kann. Ein l&uuml;ckenloser
                Schutz der Daten vor dem Zugriff durch Dritte ist nicht
                m&ouml;glich.
              </span>
            </p>

            <p>
              <span>Hinweis zur verantwortlichen Stelle</span>
            </p>
            <p>
              <span>
                Die verantwortliche Stelle f&uuml;r die Datenverarbeitung auf
                dieser Website ist:
              </span>
            </p>

            <p>Mustafa Nemat Ali</p>
            <p>L&uuml;becker Str. 37</p>
            <p>19053 Schwerin</p>
            <p>Deutschland</p>

            <p>Telefon: +49 1525 1927228</p>
            <p>
              <span>E-Mail: contact@veronikabogusch.com</span>
            </p>

            <p>
              <span>
                Verantwortliche Stelle ist die nat&uuml;rliche oder juristische
                Person, die allein oder gemeinsam mit anderen &uuml;ber die
                Zwecke und Mittel der Verarbeitung von personenbezogenen Daten
                (z. B. Namen, E-Mail-Adressen o. &Auml;.) entscheidet.
              </span>
            </p>

            <p>
              <span>Widerruf Ihrer Einwilligung zur Datenverarbeitung</span>
            </p>
            <p>
              <span>
                Viele Datenverarbeitungsvorg&auml;nge sind nur mit Ihrer
                ausdr&uuml;cklichen Einwilligung m&ouml;glich. Sie k&ouml;nnen
                eine bereits erteilte Einwilligung jederzeit widerrufen. Dazu
                reicht eine formlose Mitteilung per E-Mail an uns. Die
                Rechtm&auml;&szlig;igkeit der bis zum Widerruf erfolgten
                Datenverarbeitung bleibt vom Widerruf unber&uuml;hrt.
              </span>
            </p>

            <p>
              <span>
                Widerspruchsrecht gegen die Datenerhebung in besonderen
                F&auml;llen sowie gegen Direktwerbung (Art. 21 DSGVO)
              </span>
            </p>
            <p>
              <span>
                WENN DIE DATENVERARBEITUNG AUF GRUNDLAGE VON ART. 6 ABS. 1 LIT.
                E ODER F DSGVO ERFOLGT, HABEN SIE JEDERZEIT DAS RECHT, AUS
                GR&Uuml;NDEN, DIE SICH AUS IHRER BESONDEREN SITUATION ERGEBEN,
                GEGEN DIE VERARBEITUNG IHRER PERSONENBEZOGENEN DATEN WIDERSPRUCH
                EINZULEGEN; DIES GILT AUCH F&Uuml;R EIN AUF DIESE BESTIMMUNGEN
                GEST&Uuml;TZTES PROFILING. DIE JEWEILIGE RECHTSGRUNDLAGE, AUF
                DENEN EINE VERARBEITUNG BERUHT, ENTNEHMEN SIE DIESER
                DATENSCHUTZERKL&Auml;RUNG. WENN SIE WIDERSPRUCH EINLEGEN, WERDEN
                WIR IHRE BETROFFENEN PERSONENBEZOGENEN DATEN NICHT MEHR
                VERARBEITEN, ES SEI DENN, WIR K&Ouml;NNEN ZWINGENDE
                SCHUTZW&Uuml;RDIGE GR&Uuml;NDE F&Uuml;R DIE VERARBEITUNG
                NACHWEISEN, DIE IHRE INTERESSEN, RECHTE UND FREIHEITEN
                &Uuml;BERWIEGEN ODER DIE VERARBEITUNG DIENT DER GELTENDMACHUNG,
                AUS&Uuml;BUNG ODER VERTEIDIGUNG VON RECHTSANSPR&Uuml;CHEN
                (WIDERSPRUCH NACH ART. 21 ABS. 1 DSGVO).
              </span>
            </p>

            <p>
              <span>
                WERDEN IHRE PERSONENBEZOGENEN DATEN VERARBEITET, UM
                DIREKTWERBUNG ZU BETREIBEN, SO HABEN SIE DAS RECHT, JEDERZEIT
                WIDERSPRUCH GEGEN DIE VERARBEITUNG SIE BETREFFENDER
                PERSONENBEZOGENER DATEN ZUM ZWECKE DERARTIGER WERBUNG
                EINZULEGEN; DIES GILT AUCH F&Uuml;R DAS PROFILING, SOWEIT ES MIT
                SOLCHER DIREKTWERBUNG IN VERBINDUNG STEHT. WENN SIE
                WIDERSPRECHEN, WERDEN IHRE PERSONENBEZOGENEN DATEN ANSCHLIESSEND
                NICHT MEHR ZUM ZWECKE DER DIREKTWERBUNG VERWENDET (WIDERSPRUCH
                NACH ART. 21 ABS. 2 DSGVO).
              </span>
            </p>

            <p>
              <span>
                Beschwerderecht bei der zust&auml;ndigen Aufsichtsbeh&ouml;rde
              </span>
            </p>
            <p>
              <span>
                Im Falle von Verst&ouml;&szlig;en gegen die DSGVO steht den
                Betroffenen ein Beschwerderecht bei einer Aufsichtsbeh&ouml;rde,
                insbesondere in dem Mitgliedstaat ihres gew&ouml;hnlichen
                Aufenthalts, ihres Arbeitsplatzes oder des Orts des
                mutma&szlig;lichen Versto&szlig;es zu. Das Beschwerderecht
                besteht unbeschadet anderweitiger verwaltungsrechtlicher oder
                gerichtlicher Rechtsbehelfe.
              </span>
            </p>

            <p>
              <span>Recht auf Daten&uuml;bertragbarkeit</span>
            </p>
            <p>
              <span>
                Sie haben das Recht, Daten, die wir auf Grundlage Ihrer
                Einwilligung oder in Erf&uuml;llung eines Vertrags automatisiert
                verarbeiten, an sich oder an einen Dritten in einem
                g&auml;ngigen, maschinenlesbaren Format aush&auml;ndigen zu
                lassen. Sofern Sie die direkte &Uuml;bertragung der Daten an
                einen anderen Verantwortlichen verlangen, erfolgt dies nur,
                soweit es technisch machbar ist.
              </span>
            </p>

            <p>SSL- bzw. TLS-Verschl&uuml;sselung</p>
            <p>
              <span>
                Diese Seite nutzt aus Sicherheitsgr&uuml;nden und zum Schutz der
                &Uuml;bertragung vertraulicher Inhalte, wie zum Beispiel
                Bestellungen oder Anfragen, die Sie an uns als Seitenbetreiber
                senden, eine SSL- bzw. TLS-Verschl&uuml;sselung. Eine
                verschl&uuml;sselte Verbindung erkennen Sie daran, dass die
                Adresszeile des Browsers von &bdquo;http://&ldquo; auf
                &bdquo;http://&ldquo; wechselt und an dem Schloss-Symbol in
                Ihrer Browserzeile.
              </span>
            </p>

            <p>
              <span>
                Wenn die SSL- bzw. TLS-Verschl&uuml;sselung aktiviert ist,
                k&ouml;nnen die Daten, die Sie an uns &uuml;bermitteln, nicht
                von Dritten mitgelesen werden.
              </span>
            </p>

            <p>
              <span>Auskunft, L&ouml;schung und Berichtigung</span>
            </p>
            <p>
              <span>
                Sie haben im Rahmen der geltenden gesetzlichen Bestimmungen
                jederzeit das Recht auf unentgeltliche Auskunft &uuml;ber Ihre
                gespeicherten personenbezogenen Daten, deren Herkunft und
                Empf&auml;nger und den Zweck der Datenverarbeitung und ggf. ein
                Recht auf Berichtigung oder L&ouml;schung dieser Daten. Hierzu
                sowie zu weiteren Fragen zum Thema personenbezogene Daten
                k&ouml;nnen Sie sich jederzeit unter der im Impressum
                angegebenen Adresse an uns wenden.
              </span>
            </p>

            <p>
              <span>Recht auf Einschr&auml;nkung der Verarbeitung</span>
            </p>
            <p>
              <span>
                Sie haben das Recht, die Einschr&auml;nkung der Verarbeitung
                Ihrer personenbezogenen Daten zu verlangen. Hierzu k&ouml;nnen
                Sie sich jederzeit unter der im Impressum angegebenen Adresse an
                uns wenden. Das Recht auf Einschr&auml;nkung der Verarbeitung
                besteht in folgenden F&auml;llen:
              </span>
            </p>

            <p>
              <span>
                Wenn Sie die Richtigkeit Ihrer bei uns gespeicherten
                personenbezogenen Daten bestreiten, ben&ouml;tigen wir in der
                Regel Zeit, um dies zu &uuml;berpr&uuml;fen. F&uuml;r die Dauer
                der Pr&uuml;fung haben Sie das Recht, die Einschr&auml;nkung der
                Verarbeitung Ihrer personenbezogenen Daten zu verlangen.
              </span>
            </p>
            <p>
              <span>
                Wenn die Verarbeitung Ihrer personenbezogenen Daten
                unrechtm&auml;&szlig;ig geschah/geschieht, k&ouml;nnen Sie statt
                der L&ouml;schung die Einschr&auml;nkung der Datenverarbeitung
                verlangen.
              </span>
            </p>
            <p>
              <span>
                Wenn wir Ihre personenbezogenen Daten nicht mehr ben&ouml;tigen,
                Sie sie jedoch zur Aus&uuml;bung, Verteidigung oder
                Geltendmachung von Rechtsanspr&uuml;chen ben&ouml;tigen, haben
                Sie das Recht, statt der L&ouml;schung die Einschr&auml;nkung
                der Verarbeitung Ihrer personenbezogenen Daten zu verlangen.
              </span>
            </p>
            <p>
              <span>
                Wenn Sie einen Widerspruch nach Art. 21 Abs. 1 DSGVO eingelegt
                haben, muss eine Abw&auml;gung zwischen Ihren und unseren
                Interessen vorgenommen werden. Solange noch nicht feststeht,
                wessen Interessen &uuml;berwiegen, haben Sie das Recht, die
                Einschr&auml;nkung der Verarbeitung Ihrer personenbezogenen
                Daten zu verlangen.
              </span>
            </p>
            <p>
              <span>
                Wenn Sie die Verarbeitung Ihrer personenbezogenen Daten
                eingeschr&auml;nkt haben, d&uuml;rfen diese Daten &ndash; von
                ihrer Speicherung abgesehen &ndash; nur mit Ihrer Einwilligung
                oder zur Geltendmachung, Aus&uuml;bung oder Verteidigung von
                Rechtsanspr&uuml;chen oder zum Schutz der Rechte einer anderen
                nat&uuml;rlichen oder juristischen Person oder aus Gr&uuml;nden
                eines wichtigen &ouml;ffentlichen Interesses der
                Europ&auml;ischen Union oder eines Mitgliedstaats verarbeitet
                werden.
              </span>
            </p>

            <p>Widerspruch gegen Werbe-E-Mails</p>
            <p>
              <span>
                Der Nutzung von im Rahmen der Impressumspflicht
                ver&ouml;ffentlichten Kontaktdaten zur &Uuml;bersendung von
                nicht ausdr&uuml;cklich angeforderter Werbung und
                Informationsmaterialien wird hiermit widersprochen. Die
                Betreiber der Seiten behalten sich ausdr&uuml;cklich rechtliche
                Schritte im Falle der unverlangten Zusendung von
                Werbeinformationen, etwa durch Spam-E-Mails, vor.
              </span>
            </p>

            <p>
              <span>4. Datenerfassung auf dieser Website</span>
            </p>
            <p>Cookies</p>
            <p>
              <span>
                Unsere Internetseiten verwenden so genannte
                &bdquo;Cookies&ldquo;. Cookies sind kleine Textdateien und
                richten auf Ihrem Endger&auml;t keinen Schaden an. Sie werden
                entweder vor&uuml;bergehend f&uuml;r die Dauer einer Sitzung
                (Session-Cookies) oder dauerhaft (permanente Cookies) auf Ihrem
                Endger&auml;t gespeichert. Session-Cookies werden nach Ende
                Ihres Besuchs automatisch gel&ouml;scht. Permanente Cookies
                bleiben auf Ihrem Endger&auml;t gespeichert bis Sie diese selbst
                l&ouml;schen oder eine automatische L&ouml;sung durch Ihren
                Webbrowser erfolgt.
              </span>
            </p>

            <p>
              <span>
                Teilweise k&ouml;nnen auch Cookies von Drittunternehmen auf
                Ihrem Endger&auml;t gespeichert werden, wenn Sie unsere Seite
                betreten (Third-Party-Cookies). Diese erm&ouml;glichen uns oder
                Ihnen die Nutzung bestimmter Dienstleistungen des
                Drittunternehmens (z.B. Cookies zur Abwicklung von
                Zahlungsdienstleistungen).
              </span>
            </p>

            <p>
              <span>
                Cookies haben verschiedene Funktionen. Zahlreiche Cookies sind
                technisch notwendig, da bestimmte Webseitenfunktionen ohne diese
                nicht funktionieren w&uuml;rden (z.B. die Warenkorbfunktion oder
                die Anzeige von Videos). Andere Cookies dienen dazu das
                Nutzerverhalten auszuwerten oder Werbung anzuzeigen.
              </span>
            </p>

            <p>
              <span>
                Cookies, die zur Durchf&uuml;hrung des elektronischen
                Kommunikationsvorgangs oder zur Bereitstellung bestimmter, von
                Ihnen erw&uuml;nschter Funktionen (z. B. Warenkorbfunktion)
                erforderlich sind, werden auf Grundlage von Art. 6 Abs. 1 lit. f
                DSGVO gespeichert. Der Websitebetreiber hat ein berechtigtes
                Interesse an der Speicherung von Cookies zur technisch
                fehlerfreien und optimierten Bereitstellung seiner Dienste.
                Sofern eine entsprechende Einwilligung abgefragt wurde (z. B.
                eine Einwilligung zur Speicherung von Cookies), erfolgt die
                Verarbeitung ausschlie&szlig;lich auf Grundlage von Art. 6 Abs.
                1 lit. a DSGVO; die Einwilligung ist jederzeit widerrufbar.
              </span>
            </p>

            <p>
              <span>
                Sie k&ouml;nnen Ihren Browser so einstellen, dass Sie &uuml;ber
                das Setzen von Cookies informiert werden und Cookies nur im
                Einzelfall erlauben, die Annahme von Cookies f&uuml;r bestimmte
                F&auml;lle oder generell ausschlie&szlig;en sowie das
                automatische L&ouml;schen der Cookies beim Schlie&szlig;en des
                Browsers aktivieren. Bei der Deaktivierung von Cookies kann die
                Funktionalit&auml;t dieser Website eingeschr&auml;nkt sein.
              </span>
            </p>

            <p>
              <span>
                Soweit Cookies von Drittunternehmen oder zu Analysezwecken
                eingesetzt werden, werden wir Sie hier&uuml;ber im Rahmen dieser
                Datenschutzerkl&auml;rung gesondert informieren und ggf. eine
                Einwilligung abfragen.
              </span>
            </p>

            <p>
              <span>Cookie-Einwilligung mit Borlabs Cookie</span>
            </p>
            <p>
              <span>
                Unsere Website nutzt die Cookie-Consent-Technologie von Borlabs
                Cookie, um Ihre Einwilligung zur Speicherung bestimmter Cookies
                in Ihrem Browser einzuholen und diese datenschutzkonform zu
                dokumentieren. Anbieter dieser Technologie ist Borlabs &ndash;
                Benjamin A. Bornschein, Georg-Wilhelm-Str. 17, 21107 Hamburg (im
                Folgenden Borlabs).
              </span>
            </p>

            <p>
              <span>
                Wenn Sie unsere Website betreten, wird ein Borlabs-Cookie in
                Ihrem Browser gespeichert, in dem die von Ihnen erteilten
                Einwilligungen oder der Widerruf dieser Einwilligungen
                gespeichert werden. Diese Daten werden nicht an den Anbieter von
                Borlabs Cookie weitergegeben.
              </span>
            </p>

            <p>
              <span>
                Die erfassten Daten werden gespeichert, bis Sie uns zur
                L&ouml;schung auffordern bzw. das Borlabs-Cookie selbst
                l&ouml;schen oder der Zweck f&uuml;r die Datenspeicherung
                entf&auml;llt. Zwingende gesetzliche Aufbewahrungsfristen
                bleiben unber&uuml;hrt. Details zur Datenverarbeitung von
                Borlabs Cookie finden Sie unter
                http://de.borlabs.io/kb/welche-daten-speichert-borlabs-cookie/
              </span>
            </p>

            <p>
              <span>
                Der Einsatz der Borlabs-Cookie-Consent-Technologie erfolgt, um
                die gesetzlich vorgeschriebenen Einwilligungen f&uuml;r den
                Einsatz von Cookies einzuholen. Rechtsgrundlage hierf&uuml;r ist
                Art. 6 Abs. 1 S. 1 lit. c DSGVO.
              </span>
            </p>

            <p>Server-Log-Dateien</p>
            <p>
              <span>
                Der Provider der Seiten erhebt und speichert automatisch
                Informationen in so genannten Server-Log-Dateien, die Ihr
                Browser automatisch an uns &uuml;bermittelt. Dies sind:
              </span>
            </p>

            <p>Browsertyp und Browserversion</p>
            <p>verwendetes Betriebssystem</p>
            <p>Referrer URL</p>
            <p>Hostname des zugreifenden Rechners</p>
            <p>Uhrzeit der Serveranfrage</p>
            <p>IP-Adresse</p>
            <p>
              <span>
                Eine Zusammenf&uuml;hrung dieser Daten mit anderen Datenquellen
                wird nicht vorgenommen.
              </span>
            </p>

            <p>
              <span>
                Die Erfassung dieser Daten erfolgt auf Grundlage von Art. 6 Abs.
                1 lit. f DSGVO. Der Websitebetreiber hat ein berechtigtes
                Interesse an der technisch fehlerfreien Darstellung und der
                Optimierung seiner Website &ndash; hierzu m&uuml;ssen die
                Server-Log-Files erfasst werden.
              </span>
            </p>

            <p>Kontaktformular</p>
            <p>
              <span>
                Wenn Sie uns per Kontaktformular Anfragen zukommen lassen,
                werden Ihre Angaben aus dem Anfrageformular inklusive der von
                Ihnen dort angegebenen Kontaktdaten zwecks Bearbeitung der
                Anfrage und f&uuml;r den Fall von Anschlussfragen bei uns
                gespeichert. Diese Daten geben wir nicht ohne Ihre Einwilligung
                weiter.
              </span>
            </p>

            <p>
              <span>
                Die Verarbeitung dieser Daten erfolgt auf Grundlage von Art. 6
                Abs. 1 lit. b DSGVO, sofern Ihre Anfrage mit der Erf&uuml;llung
                eines Vertrags zusammenh&auml;ngt oder zur Durchf&uuml;hrung
                vorvertraglicher Ma&szlig;nahmen erforderlich ist. In allen
                &uuml;brigen F&auml;llen beruht die Verarbeitung auf unserem
                berechtigten Interesse an der effektiven Bearbeitung der an uns
                gerichteten Anfragen (Art. 6 Abs. 1 lit. f DSGVO) oder auf Ihrer
                Einwilligung (Art. 6 Abs. 1 lit. a DSGVO) sofern diese abgefragt
                wurde.
              </span>
            </p>

            <p>
              <span>
                Die von Ihnen im Kontaktformular eingegebenen Daten verbleiben
                bei uns, bis Sie uns zur L&ouml;schung auffordern, Ihre
                Einwilligung zur Speicherung widerrufen oder der Zweck f&uuml;r
                die Datenspeicherung entf&auml;llt (z. B. nach abgeschlossener
                Bearbeitung Ihrer Anfrage). Zwingende gesetzliche Bestimmungen
                &ndash; insbesondere Aufbewahrungsfristen &ndash; bleiben
                unber&uuml;hrt.
              </span>
            </p>

            <p>
              <span>Anfrage per E-Mail, Telefon oder Telefax</span>
            </p>
            <p>
              <span>
                Wenn Sie uns per E-Mail, Telefon oder Telefax kontaktieren, wird
                Ihre Anfrage inklusive aller daraus hervorgehenden
                personenbezogenen Daten (Name, Anfrage) zum Zwecke der
                Bearbeitung Ihres Anliegens bei uns gespeichert und verarbeitet.
                Diese Daten geben wir nicht ohne Ihre Einwilligung weiter.
              </span>
            </p>

            <p>
              <span>
                Die Verarbeitung dieser Daten erfolgt auf Grundlage von Art. 6
                Abs. 1 lit. b DSGVO, sofern Ihre Anfrage mit der Erf&uuml;llung
                eines Vertrags zusammenh&auml;ngt oder zur Durchf&uuml;hrung
                vorvertraglicher Ma&szlig;nahmen erforderlich ist. In allen
                &uuml;brigen F&auml;llen beruht die Verarbeitung auf Ihrer
                Einwilligung (Art. 6 Abs. 1 lit. a DSGVO) und/oder auf unseren
                berechtigten Interessen (Art. 6 Abs. 1 lit. f DSGVO), da wir ein
                berechtigtes Interesse an der effektiven Bearbeitung der an uns
                gerichteten Anfragen haben.
              </span>
            </p>

            <p>
              <span>
                Die von Ihnen an uns per Kontaktanfragen &uuml;bersandten Daten
                verbleiben bei uns, bis Sie uns zur L&ouml;schung auffordern,
                Ihre Einwilligung zur Speicherung widerrufen oder der Zweck
                f&uuml;r die Datenspeicherung entf&auml;llt (z. B. nach
                abgeschlossener Bearbeitung Ihres Anliegens). Zwingende
                gesetzliche Bestimmungen &ndash; insbesondere gesetzliche
                Aufbewahrungsfristen &ndash; bleiben unber&uuml;hrt.
              </span>
            </p>

            <p>5. Soziale Medien</p>
            <p>eRecht24 Safe Sharing Tool</p>
            <p>
              <span>
                Die Inhalte auf dieser Website k&ouml;nnen datenschutzkonform in
                sozialen Netzwerken wie Facebook, Twitter &amp; Co. geteilt
                werden. Diese Seite nutzt daf&uuml;r das eRecht24 Safe Sharing
                Tool. Dieses Tool stellt den direkten Kontakt zwischen den
                Netzwerken und Nutzern erst dann her, wenn der Nutzer aktiv auf
                einen dieser Button klickt. Der Klick auf den Button stellt eine
                Einwilligung im Sinne des Art. 6 Abs. 1 lit. a DSGVO dar. Diese
                Einwilligung kann jederzeit mit Wirkung f&uuml;r die Zukunft
                widerrufen werden.
              </span>
            </p>

            <p>
              <span>
                Eine automatische &Uuml;bertragung von Nutzerdaten an die
                Betreiber dieser Plattformen erfolgt durch dieses Tool nicht.
                Ist der Nutzer bei einem der sozialen Netzwerke angemeldet,
                erscheint bei der Nutzung der Social-Buttons von Facebook,
                Twitter &amp; Co. ein Informations-Fenster, in dem der Nutzer
                den Text vor dem Absenden best&auml;tigen kann.
              </span>
            </p>

            <p>
              <span>
                Unsere Nutzer k&ouml;nnen die Inhalte dieser Seite
                datenschutzkonform in sozialen Netzwerken teilen, ohne dass
                komplette Surf-Profile durch die Betreiber der Netzwerke
                erstellt werden.
              </span>
            </p>

            <p>Social-Media-Plugins mit Shariff</p>
            <p>
              <span>
                Auf dieser Website werden Plugins von sozialen Medien verwendet
                (z. B. Facebook, Twitter, Instagram, Pinterest, XING, LinkedIn,
                Tumblr).
              </span>
            </p>

            <p>
              <span>
                Die Plugins k&ouml;nnen Sie in der Regel anhand der jeweiligen
                Social-Media-Logos erkennen. Um den Datenschutz auf dieser
                Website zu gew&auml;hrleisten, verwenden wir diese Plugins nur
                zusammen mit der sogenannten &bdquo;Shariff&ldquo;-L&ouml;sung.
                Diese Anwendung verhindert, dass die auf dieser Website
                integrierten Plugins Daten schon beim ersten Betreten der Seite
                an den jeweiligen Anbieter &uuml;bertragen.
              </span>
            </p>

            <p>
              <span>
                Erst wenn Sie das jeweilige Plugin durch Anklicken der
                zugeh&ouml;rigen Schaltfl&auml;che aktivieren, wird eine direkte
                Verbindung zum Server des Anbieters hergestellt (Einwilligung).
                Sobald Sie das Plugin aktivieren, erh&auml;lt der jeweilige
                Anbieter die Information, dass Sie mit Ihrer IP-Adresse dieser
                Website besucht haben. Wenn Sie gleichzeitig in Ihrem jeweiligen
                Social-Media-Account (z. B. Facebook) eingeloggt sind, kann der
                jeweilige Anbieter den Besuch dieser Website Ihrem Benutzerkonto
                zuordnen.
              </span>
            </p>

            <p>
              <span>
                Das Aktivieren des Plugins stellt eine Einwilligung im Sinne des
                Art. 6 Abs. 1 lit. a DSGVO dar. Diese Einwilligung k&ouml;nnen
                Sie jederzeit mit Wirkung f&uuml;r die Zukunft widerrufen.
              </span>
            </p>

            <p>
              <span>Facebook Plugins (Like &amp; Share-Button)</span>
            </p>
            <p>
              <span>
                Auf dieser Website sind Plugins des sozialen Netzwerks Facebook
                integriert. Anbieter dieses Dienstes ist die Facebook Ireland
                Limited, 4 Grand Canal Square, Dublin 2, Irland. Die erfassten
                Daten werden nach Aussage von Facebook jedoch auch in die USA
                und in andere Drittl&auml;nder &uuml;bertragen.
              </span>
            </p>

            <p>
              <span>
                Die Facebook Plugins erkennen Sie an dem Facebook-Logo oder dem
                &bdquo;Like-Button&ldquo; (&bdquo;Gef&auml;llt mir&ldquo;) auf
                dieser Website. Eine &Uuml;bersicht &uuml;ber die Facebook
                Plugins finden Sie hier:
                http://developers.facebook.com/docs/plugins/?locale=de_DE.
              </span>
            </p>

            <p>
              <span>
                Wenn Sie diese Website besuchen, wird &uuml;ber das Plugin eine
                direkte Verbindung zwischen Ihrem Browser und dem
                Facebook-Server hergestellt. Facebook erh&auml;lt dadurch die
                Information, dass Sie mit Ihrer IP-Adresse diese Website besucht
                haben. Wenn Sie den Facebook &bdquo;Like-Button&ldquo; anklicken
                w&auml;hrend Sie in Ihrem Facebook-Account eingeloggt sind,
                k&ouml;nnen Sie die Inhalte dieser Website auf Ihrem
                Facebook-Profil verlinken. Dadurch kann Facebook den Besuch
                dieser Website Ihrem Benutzerkonto zuordnen. Wir weisen darauf
                hin, dass wir als Anbieter der Seiten keine Kenntnis vom Inhalt
                der &uuml;bermittelten Daten sowie deren Nutzung durch Facebook
                erhalten. Weitere Informationen hierzu finden Sie in der
                Datenschutzerkl&auml;rung von Facebook unter:
                http://de-de.facebook.com/privacy/explanation.
              </span>
            </p>

            <p>
              <span>
                Wenn Sie nicht w&uuml;nschen, dass Facebook den Besuch dieser
                Website Ihrem Facebook-Nutzerkonto zuordnen kann, loggen Sie
                sich bitte aus Ihrem Facebook-Benutzerkonto aus.
              </span>
            </p>

            <p>
              <span>
                Die Verwendung der Facebook Plugins erfolgt auf Grundlage von
                Art. 6 Abs. 1 lit. f DSGVO. Der Websitebetreiber hat ein
                berechtigtes Interesse an einer m&ouml;glichst umfangreichen
                Sichtbarkeit in den Sozialen Medien.
              </span>
            </p>

            <p>Twitter Plugin</p>
            <p>
              <span>
                Auf dieser Website sind Funktionen des Dienstes Twitter
                eingebunden. Diese Funktionen werden angeboten durch die Twitter
                Inc., 1355 Market Street, Suite 900, San Francisco, CA 94103,
                USA. Durch das Benutzen von Twitter und der Funktion
                &bdquo;Re-Tweet&ldquo; werden die von Ihnen besuchten Websites
                mit Ihrem Twitter-Account verkn&uuml;pft und anderen Nutzern
                bekannt gegeben. Dabei werden auch Daten an Twitter
                &uuml;bertragen. Wir weisen darauf hin, dass wir als Anbieter
                der Seiten keine Kenntnis vom Inhalt der &uuml;bermittelten
                Daten sowie deren Nutzung durch Twitter erhalten. Weitere
                Informationen hierzu finden Sie in der Datenschutzerkl&auml;rung
                von Twitter unter: http://twitter.com/de/privacy.
              </span>
            </p>

            <p>
              <span>
                Die Verwendung des Twitter-Plugins erfolgt auf Grundlage von
                Art. 6 Abs. 1 lit. f DSGVO. Der Websitebetreiber hat ein
                berechtigtes Interesse an einer m&ouml;glichst umfangreichen
                Sichtbarkeit in den Sozialen Medien.
              </span>
            </p>

            <p>
              <span>
                Ihre Datenschutzeinstellungen bei Twitter k&ouml;nnen Sie in den
                Konto-Einstellungen unter http://twitter.com/account/settings
                &auml;ndern.
              </span>
            </p>

            <p>Instagram Plugin</p>
            <p>
              <span>
                Auf dieser Website sind Funktionen des Dienstes Instagram
                eingebunden. Diese Funktionen werden angeboten durch die
                Instagram Inc., 1601 Willow Road, Menlo Park, CA 94025, USA
                integriert.
              </span>
            </p>

            <p>
              <span>
                Wenn Sie in Ihrem Instagram-Account eingeloggt sind, k&ouml;nnen
                Sie durch Anklicken des Instagram-Buttons die Inhalte dieser
                Website mit Ihrem Instagram-Profil verlinken. Dadurch kann
                Instagram den Besuch dieser Website Ihrem Benutzerkonto
                zuordnen. Wir weisen darauf hin, dass wir als Anbieter der
                Seiten keine Kenntnis vom Inhalt der &uuml;bermittelten Daten
                sowie deren Nutzung durch Instagram erhalten.
              </span>
            </p>

            <p>
              <span>
                Die Speicherung und Analyse der Daten erfolgt auf Grundlage von
                Art. 6 Abs. 1 lit. f DSGVO. Der Webseitenbetreiber hat ein
                berechtigtes Interesse an einer m&ouml;glichst umfangreichen
                Sichtbarkeit in den Sozialen Medien. Sofern eine entsprechende
                Einwilligung abgefragt wurde (z. B. eine Einwilligung zur
                Speicherung von Cookies), erfolgt die Verarbeitung
                ausschlie&szlig;lich auf Grundlage von Art. 6 Abs. 1 lit. a
                DSGVO; die Einwilligung ist jederzeit widerrufbar.
              </span>
            </p>

            <p>
              <span>
                Weitere Informationen hierzu finden Sie in der
                Datenschutzerkl&auml;rung von Instagram:
                http://instagram.com/about/legal/privacy/.
              </span>
            </p>

            <p>XING Plugin</p>
            <p>
              <span>
                Diese Website nutzt Funktionen des Netzwerks XING. Anbieter ist
                die XING AG, Dammtorstra&szlig;e 29-32, 20354 Hamburg,
                Deutschland.
              </span>
            </p>

            <p>
              <span>
                Bei jedem Abruf einer unserer Seiten, die Funktionen von XING
                enth&auml;lt, wird eine Verbindung zu Servern von XING
                hergestellt. Eine Speicherung von personenbezogenen Daten
                erfolgt dabei nach unserer Kenntnis nicht. Insbesondere werden
                keine IP-Adressen gespeichert oder das Nutzungsverhalten
                ausgewertet.
              </span>
            </p>

            <p>
              <span>
                Die Speicherung und Analyse der Daten erfolgt auf Grundlage von
                Art. 6 Abs. 1 lit. f DSGVO. Der Websitebetreiber hat ein
                berechtigtes Interesse an einer m&ouml;glichst umfangreichen
                Sichtbarkeit in den Sozialen Medien. Sofern eine entsprechende
                Einwilligung abgefragt wurde (z. B. eine Einwilligung zur
                Speicherung von Cookies), erfolgt die Verarbeitung
                ausschlie&szlig;lich auf Grundlage von Art. 6 Abs. 1 lit. a
                DSGVO; die Einwilligung ist jederzeit widerrufbar.
              </span>
            </p>

            <p>
              <span>
                Weitere Information zum Datenschutz und dem XING Share-Button
                finden Sie in der Datenschutzerkl&auml;rung von XING unter:
                http://www.xing.com/app/share?op=data_protection.
              </span>
            </p>

            <p>Pinterest Plugin</p>
            <p>
              <span>
                Auf dieser Website verwenden wir Social Plugins des sozialen
                Netzwerkes Pinterest, das von der Pinterest Inc., 808 Brannan
                Street, San Francisco, CA 94103-490, USA
                (&bdquo;Pinterest&ldquo;) betrieben wird.
              </span>
            </p>

            <p>
              <span>
                Wenn Sie eine Seite aufrufen, die ein solches Plugin
                enth&auml;lt, stellt Ihr Browser eine direkte Verbindung zu den
                Servern von Pinterest her. Das Plugin &uuml;bermittelt dabei
                Protokolldaten an den Server von Pinterest in die USA. Diese
                Protokolldaten enthalten m&ouml;glicherweise Ihre IP-Adresse,
                die Adresse der besuchten Websites, die ebenfalls
                Pinterest-Funktionen enthalten, Art und Einstellungen des
                Browsers, Datum und Zeitpunkt der Anfrage, Ihre Verwendungsweise
                von Pinterest sowie Cookies.
              </span>
            </p>

            <p>
              <span>
                Die Speicherung und Analyse der Daten erfolgt auf Grundlage von
                Art. 6 Abs. 1 lit. f DSGVO. Der Webseitenbetreiber hat ein
                berechtigtes Interesse an einer m&ouml;glichst umfangreichen
                Sichtbarkeit in den Sozialen Medien. Sofern eine entsprechende
                Einwilligung abgefragt wurde (z. B. eine Einwilligung zur
                Speicherung von Cookies), erfolgt die Verarbeitung
                ausschlie&szlig;lich auf Grundlage von Art. 6 Abs. 1 lit. a
                DSGVO; die Einwilligung ist jederzeit widerrufbar.
              </span>
            </p>

            <p>
              <span>
                Weitere Informationen zu Zweck, Umfang und weiterer Verarbeitung
                und Nutzung der Daten durch Pinterest sowie Ihre
                diesbez&uuml;glichen Rechte und M&ouml;glichkeiten zum Schutz
                Ihrer Privatsph&auml;re finden Sie in den Datenschutzhinweisen
                von Pinterest: http://policy.pinterest.com/de/privacy-policy.
              </span>
            </p>

            <p>6. Analyse-Tools und Werbung</p>
            <p>Google Analytics</p>
            <p>
              <span>
                Diese Website nutzt Funktionen des Webanalysedienstes Google
                Analytics. Anbieter ist die Google Ireland Limited
                (&bdquo;Google&ldquo;), Gordon House, Barrow Street, Dublin 4,
                Irland.
              </span>
            </p>

            <p>
              <span>
                Google Analytics verwendet so genannte &bdquo;Cookies&ldquo;.
                Das sind Textdateien, die auf Ihrem Computer gespeichert werden
                und die eine Analyse der Benutzung der Website durch Sie
                erm&ouml;glichen. Die durch den Cookie erzeugten Informationen
                &uuml;ber Ihre Benutzung dieser Website werden in der Regel an
                einen Server von Google in den USA &uuml;bertragen und dort
                gespeichert.
              </span>
            </p>

            <p>
              <span>
                Die Speicherung von Google-Analytics-Cookies und die Nutzung
                dieses Analyse-Tools erfolgen auf Grundlage von Art. 6 Abs. 1
                lit. f DSGVO. Der Websitebetreiber hat ein berechtigtes
                Interesse an der Analyse des Nutzerverhaltens, um sowohl sein
                Webangebot als auch seine Werbung zu optimieren. Sofern eine
                entsprechende Einwilligung abgefragt wurde (z. B. eine
                Einwilligung zur Speicherung von Cookies), erfolgt die
                Verarbeitung ausschlie&szlig;lich auf Grundlage von Art. 6 Abs.
                1 lit. a DSGVO; die Einwilligung ist jederzeit widerrufbar.
              </span>
            </p>

            <p>IP Anonymisierung</p>

            <p>
              <span>
                Wir haben auf dieser Website die Funktion IP-Anonymisierung
                aktiviert. Dadurch wird Ihre IP-Adresse von Google innerhalb von
                Mitgliedstaaten der Europ&auml;ischen Union oder in anderen
                Vertragsstaaten des Abkommens &uuml;ber den Europ&auml;ischen
                Wirtschaftsraum vor der &Uuml;bermittlung in die USA
                gek&uuml;rzt. Nur in Ausnahmef&auml;llen wird die volle
                IP-Adresse an einen Server von Google in den USA &uuml;bertragen
                und dort gek&uuml;rzt. Im Auftrag des Betreibers dieser Website
                wird Google diese Informationen benutzen, um Ihre Nutzung der
                Website auszuwerten, um Reports &uuml;ber die
                Websiteaktivit&auml;ten zusammenzustellen und um weitere mit der
                Websitenutzung und der Internetnutzung verbundene
                Dienstleistungen gegen&uuml;ber dem Websitebetreiber zu
                erbringen. Die im Rahmen von Google Analytics von Ihrem Browser
                &uuml;bermittelte IP-Adresse wird nicht mit anderen Daten von
                Google zusammengef&uuml;hrt.
              </span>
            </p>

            <p>Browser Plugin</p>

            <p>
              <span>
                Sie k&ouml;nnen die Speicherung der Cookies durch eine
                entsprechende Einstellung Ihrer Browser-Software verhindern; wir
                weisen Sie jedoch darauf hin, dass Sie in diesem Fall
                gegebenenfalls nicht s&auml;mtliche Funktionen dieser Website
                vollumf&auml;nglich werden nutzen k&ouml;nnen. Sie k&ouml;nnen
                dar&uuml;ber hinaus die Erfassung der durch den Cookie erzeugten
                und auf Ihre Nutzung der Website bezogenen Daten (inkl. Ihrer
                IP-Adresse) an Google sowie die Verarbeitung dieser Daten durch
                Google verhindern, indem Sie das unter dem folgenden Link
                verf&uuml;gbare Browser-Plugin herunterladen und installieren:
                http://tools.google.com/dlpage/gaoptout?hl=de.
              </span>
            </p>

            <p>Widerspruch gegen Datenerfassung</p>

            <p>
              <span>
                Sie k&ouml;nnen die Erfassung Ihrer Daten durch Google Analytics
                verhindern, indem Sie auf folgenden Link klicken. Es wird ein
                Opt-Out-Cookie gesetzt, der die Erfassung Ihrer Daten bei
                zuk&uuml;nftigen Besuchen dieser Website verhindert: Google
                Analytics deaktivieren.
              </span>
            </p>

            <p>
              <span>
                Mehr Informationen zum Umgang mit Nutzerdaten bei Google
                Analytics finden Sie in der Datenschutzerkl&auml;rung von
                Google:
                http://support.google.com/analytics/answer/6004245?hl=de.
              </span>
            </p>

            <p>Auftragsverarbeitung</p>

            <p>
              <span>
                Wir haben mit Google einen Vertrag zur Auftragsverarbeitung
                abgeschlossen und setzen die strengen Vorgaben der deutschen
                Datenschutzbeh&ouml;rden bei der Nutzung von Google Analytics
                vollst&auml;ndig um.
              </span>
            </p>

            <p>
              <span>Demografische Merkmale bei Google Analytics</span>
            </p>

            <p>
              <span>
                Diese Website nutzt die Funktion &bdquo;demografische
                Merkmale&ldquo; von Google Analytics. Dadurch k&ouml;nnen
                Berichte erstellt werden, die Aussagen zu Alter, Geschlecht und
                Interessen der Seitenbesucher enthalten. Diese Daten stammen aus
                interessenbezogener Werbung von Google sowie aus Besucherdaten
                von Drittanbietern. Diese Daten k&ouml;nnen keiner bestimmten
                Person zugeordnet werden. Sie k&ouml;nnen diese Funktion
                jederzeit &uuml;ber die Anzeigeneinstellungen in Ihrem
                Google-Konto deaktivieren oder die Erfassung Ihrer Daten durch
                Google Analytics wie im Punkt &bdquo;Widerspruch gegen
                Datenerfassung&ldquo; dargestellt generell untersagen.
              </span>
            </p>

            <p>Speicherdauer</p>

            <p>
              <span>
                Bei Google gespeicherte Daten auf Nutzer- und Ereignisebene, die
                mit Cookies, Nutzerkennungen (z. B. User ID) oder Werbe-IDs (z.
                B. DoubleClick-Cookies, Android-Werbe-ID) verkn&uuml;pft sind,
                werden nach 14 Monaten anonymisiert bzw. gel&ouml;scht. Details
                hierzu ersehen Sie unter folgendem Link:
                http://support.google.com/analytics/answer/7667196?hl=de
              </span>
            </p>

            <p>
              <span>Google AdSense (nicht personalisiert)</span>
            </p>
            <p>
              <span>
                Diese Website nutzt Google AdSense, einen Dienst zum Einbinden
                von Werbeanzeigen. Anbieter ist die Google Ireland Limited
                (&bdquo;Google&ldquo;), Gordon House, Barrow Street, Dublin 4,
                Irland.
              </span>
            </p>

            <p>
              <span>
                Wir nutzen Google AdSense im
                &bdquo;nicht-personalisierten&ldquo; Modus. Im Gegensatz zum
                personalisierten Modus beruhen die Werbeanzeigen daher nicht auf
                Ihrem fr&uuml;heren Nutzerverhalten und es wird kein
                Nutzerprofil von Ihnen erstellt. Stattdessen werden bei der
                Auswahl der Werbung sogenannte
                &bdquo;Kontextinformationen&ldquo; herangezogen. Die
                ausgew&auml;hlten Werbeanzeigen richten sich dann beispielsweise
                nach Ihrem Standort, dem Inhalt der Website, auf der Sie sich
                befinden oder nach Ihren aktuellen Suchbegriffen. Mehr zu den
                Unterschieden zwischen personalisiertem und
                nicht-personalisiertem Targeting mit Google AdSense finden Sie
                unter: http://support.google.com/adsense/answer/9007336.
              </span>
            </p>

            <p>
              <span>
                Bitte beachten Sie, dass auch beim Einsatz von Google Adsense im
                nicht-personalisierten Modus Cookies gespeichert werden
                k&ouml;nnen. Diese werden laut Google zur Bek&auml;mpfung von
                Betrug und Missbrauch eingesetzt. Die Cookies verbleiben auf
                Ihrem Endger&auml;t, bis Sie sie l&ouml;schen.
              </span>
            </p>

            <p>
              <span>
                Der Einsatz von Google Adsense im nicht-personalisierten Modus
                erfolgt auf Grundlage von Art. 6 Abs. 1 lit. f DSGVO. Wir haben
                ein berechtigtes Interesse an der Analyse des Nutzerverhaltens,
                um sowohl unser Webangebot als auch unsere Werbung zu
                optimieren. Sofern eine entsprechende Einwilligung abgefragt
                wurde (z. B. eine Einwilligung zur Speicherung von Cookies),
                erfolgt die Verarbeitung ausschlie&szlig;lich auf Grundlage von
                Art. 6 Abs. 1 lit. a DSGVO; die Einwilligung ist jederzeit
                widerrufbar.
              </span>
            </p>

            <p>
              <span>
                Google verf&uuml;gt &uuml;ber eine Zertifizierung nach dem
                &bdquo;EU-US-Privacy-Shield&ldquo;. Der Privacy-Shield ist ein
                &Uuml;bereikommen zwischen der Europ&auml;ischen Union und den
                USA, der die Einhaltung europ&auml;ischer Datenschutzstandards
                bei Datenverarbeitungen in den USA gew&auml;hrleisten soll.
                Jedes nach dem Privacy-Shield zertifizierte Unternehmen
                verpflichtet sich dazu, diese Datenschutzstandards einzuhalten.
              </span>
            </p>

            <p>
              <span>
                Sie k&ouml;nnen Ihre Werbeeinstellungen selbstst&auml;ndig in
                Ihrem Nutzer-Account anpassen. Klicken Sie hierzu auf folgenden
                Link und loggen Sie sich ein:
                http://adssettings.google.com/authenticated.
              </span>
            </p>

            <p>
              <span>
                Weitere Informationen zu den Werbetechnologien von Google finden
                Sie hier:
              </span>
            </p>
            <p>
              <span>http://policies.google.com/technologies/ads</span>
            </p>
            <p>
              <span>http://www.google.de/intl/de/policies/privacy/.</span>
            </p>

            <p>Google Analytics Remarketing</p>
            <p>
              <span>
                Diese Website nutzt die Funktionen von Google Analytics
                Remarketing in Verbindung mit den ger&auml;te&uuml;bergreifenden
                Funktionen von Google Ads und Google DoubleClick. Anbieter ist
                die Google Ireland Limited (&bdquo;Google&ldquo;), Gordon House,
                Barrow Street, Dublin 4, Irland.
              </span>
            </p>

            <p>
              <span>
                Diese Funktion erm&ouml;glicht es die mit Google Analytics
                Remarketing erstellten Werbe-Zielgruppen mit den
                ger&auml;te&uuml;bergreifenden Funktionen von Google Ads und
                Google DoubleClick zu verkn&uuml;pfen. Auf diese Weise
                k&ouml;nnen interessenbezogene, personalisierte
                Werbebotschaften, die in Abh&auml;ngigkeit Ihres fr&uuml;heren
                Nutzungs- und Surfverhaltens auf einem Endger&auml;t (z. B.
                Handy) an Sie angepasst wurden auch auf einem anderen Ihrer
                Endger&auml;te (z. B. Tablet oder PC) angezeigt werden.
              </span>
            </p>

            <p>
              <span>
                Haben Sie eine entsprechende Einwilligung erteilt,
                verkn&uuml;pft Google zu diesem Zweck Ihren Web- und
                App-Browserverlauf mit Ihrem Google-Konto. Auf diese Weise
                k&ouml;nnen auf jedem Endger&auml;t auf dem Sie sich mit Ihrem
                Google-Konto anmelden, dieselben personalisierten
                Werbebotschaften geschaltet werden.
              </span>
            </p>

            <p>
              <span>
                Zur Unterst&uuml;tzung dieser Funktion erfasst Google Analytics
                google-authentifizierte IDs der Nutzer, die vor&uuml;bergehend
                mit unseren Google-Analytics-Daten verkn&uuml;pft werden, um
                Zielgruppen f&uuml;r die ger&auml;te&uuml;bergreifende
                Anzeigenwerbung zu definieren und zu erstellen.
              </span>
            </p>

            <p>
              <span>
                Sie k&ouml;nnen dem ger&auml;te&uuml;bergreifenden
                Remarketing/Targeting dauerhaft widersprechen, indem Sie
                personalisierte Werbung deaktivieren; folgen Sie hierzu diesem
                Link: http://www.google.com/settings/ads/onweb/.
              </span>
            </p>

            <p>
              <span>
                Die Zusammenfassung der erfassten Daten in Ihrem Google-Konto
                erfolgt ausschlie&szlig;lich auf Grundlage Ihrer Einwilligung,
                die Sie bei Google abgeben oder widerrufen k&ouml;nnen (Art. 6
                Abs. 1 lit. a DSGVO). Bei Datenerfassungsvorg&auml;ngen, die
                nicht in Ihrem Google-Konto zusammengef&uuml;hrt werden (z. B.
                weil Sie kein Google-Konto haben oder der Zusammenf&uuml;hrung
                widersprochen haben) beruht die Erfassung der Daten auf Art. 6
                Abs. 1 lit. f DSGVO. Das berechtigte Interesse ergibt sich
                daraus, dass der Websitebetreiber ein Interesse an der
                anonymisierten Analyse der Websitebesucher zu Werbezwecken hat.
              </span>
            </p>

            <p>
              <span>
                Weitergehende Informationen und die Datenschutzbestimmungen
                finden Sie in der Datenschutzerkl&auml;rung von Google unter:
                http://policies.google.com/technologies/ads?hl=de.
              </span>
            </p>

            <p>
              <span>Google Ads und Google Conversion-Tracking</span>
            </p>
            <p>
              <span>
                Diese Website verwendet Google Ads. Google Ads ist ein
                Online-Werbeprogramm der Google Ireland Limited
                (&bdquo;Google&ldquo;), Gordon House, Barrow Street, Dublin 4,
                Irland.
              </span>
            </p>

            <p>
              <span>
                Im Rahmen von Google Ads nutzen wir das so genannte
                Conversion-Tracking. Wenn Sie auf eine von Google geschaltete
                Anzeige klicken wird ein Cookie f&uuml;r das Conversion-Tracking
                gesetzt. Bei Cookies handelt es sich um kleine Textdateien, die
                der Internet-Browser auf dem Computer des Nutzers ablegt. Diese
                Cookies verlieren nach 30 Tagen ihre G&uuml;ltigkeit und dienen
                nicht der pers&ouml;nlichen Identifizierung der Nutzer. Besucht
                der Nutzer bestimmte Seiten dieser Website und das Cookie ist
                noch nicht abgelaufen, k&ouml;nnen Google und wir erkennen, dass
                der Nutzer auf die Anzeige geklickt hat und zu dieser Seite
                weitergeleitet wurde.
              </span>
            </p>

            <p>
              <span>
                Jeder Google Ads-Kunde erh&auml;lt ein anderes Cookie. Die
                Cookies k&ouml;nnen nicht &uuml;ber die Websites von Google
                Ads-Kunden nachverfolgt werden. Die mithilfe des
                Conversion-Cookies eingeholten Informationen dienen dazu,
                Conversion-Statistiken f&uuml;r Google Ads-Kunden zu erstellen,
                die sich f&uuml;r Conversion-Tracking entschieden haben. Die
                Kunden erfahren die Gesamtanzahl der Nutzer, die auf ihre
                Anzeige geklickt haben und zu einer mit einem
                Conversion-Tracking-Tag versehenen Seite weitergeleitet wurden.
                Sie erhalten jedoch keine Informationen, mit denen sich Nutzer
                pers&ouml;nlich identifizieren lassen. Wenn Sie nicht am
                Tracking teilnehmen m&ouml;chten, k&ouml;nnen Sie dieser Nutzung
                widersprechen, indem Sie das Cookie des Google
                Conversion-Trackings &uuml;ber ihren Internet-Browser unter
                Nutzereinstellungen leicht deaktivieren. Sie werden sodann nicht
                in die Conversion-Tracking Statistiken aufgenommen.
              </span>
            </p>

            <p>
              <span>
                Die Speicherung von &bdquo;Conversion-Cookies&ldquo; und die
                Nutzung dieses Tracking-Tools erfolgen auf Grundlage von Art. 6
                Abs. 1 lit. f DSGVO. Der Websitebetreiber hat ein berechtigtes
                Interesse an der Analyse des Nutzerverhaltens, um sowohl sein
                Webangebot als auch seine Werbung zu optimieren. Sofern eine
                entsprechende Einwilligung abgefragt wurde (z. B. eine
                Einwilligung zur Speicherung von Cookies), erfolgt die
                Verarbeitung ausschlie&szlig;lich auf Grundlage von Art. 6 Abs.
                1 lit. a DSGVO; die Einwilligung ist jederzeit widerrufbar.
              </span>
            </p>

            <p>
              <span>
                Mehr Informationen zu Google Ads und Google Conversion-Tracking
                finden Sie in den Datenschutzbestimmungen von Google:
                http://policies.google.com/privacy?hl=de.
              </span>
            </p>

            <p>
              <span>
                Sie k&ouml;nnen Ihren Browser so einstellen, dass Sie &uuml;ber
                das Setzen von Cookies informiert werden und Cookies nur im
                Einzelfall erlauben, die Annahme von Cookies f&uuml;r bestimmte
                F&auml;lle oder generell ausschlie&szlig;en sowie das
                automatische L&ouml;schen der Cookies beim Schlie&szlig;en des
                Browsers aktivieren. Bei der Deaktivierung von Cookies kann die
                Funktionalit&auml;t dieser Website eingeschr&auml;nkt sein.
              </span>
            </p>

            <p>Google DoubleClick</p>
            <p>
              <span>
                Diese Website nutzt Funktionen von Google DoubleClick. Anbieter
                ist die Google LLC, 1600 Amphitheatre Parkway, Mountain View, CA
                94043, USA, (nachfolgend &bdquo;DoubleClick&ldquo;).
              </span>
            </p>

            <p>
              <span>
                DoubleClick wird eingesetzt, um Ihnen interessenbezogene
                Werbeanzeigen im gesamten Google-Werbenetzwerk anzuzeigen. Die
                Werbeanzeigen k&ouml;nnen mit Hilfe von DoubleClick
                zielgerichtet an die Interessen des jeweiligen Betrachters
                angepasst werden. So kann unsere Werbung beispielsweise in
                Google-Suchergebnissen oder in Werbebannern, die mit DoubleClick
                verbunden sind, angezeigt werden.
              </span>
            </p>

            <p>
              <span>
                Um den Nutzern interessengerechte Werbung anzeigen zu
                k&ouml;nnen, muss DoubleClick den jeweiligen Betrachter
                wiedererkennen k&ouml;nnen. Hierzu wird im Browser des Nutzers
                ein Cookie gespeichert, hinter welchem die vom Nutzer besuchten
                Webseiten, Klicks und verschiedene weitere Informationen
                hinterlegt sind. Diese Informationen werden zu einem pseudonymen
                Nutzerprofil zusammengefasst, um dem betreffenden Nutzer
                interessengerechte Werbung anzuzeigen.
              </span>
            </p>

            <p>
              <span>
                Die Nutzung von Google DoubleClick erfolgt im Interesse
                zielgerichteter Werbema&szlig;nahmen. Dies stellt ein
                berechtigtes Interesse im Sinne des Art. 6 Abs. 1 lit. f DSGVO
                dar. Sofern eine entsprechende Einwilligung abgefragt wurde (z.
                B. eine Einwilligung zur Speicherung von Cookies), erfolgt die
                Verarbeitung ausschlie&szlig;lich auf Grundlage von Art. 6 Abs.
                1 lit. a DSGVO; die Einwilligung ist jederzeit widerrufbar.
              </span>
            </p>

            <p>
              <span>
                Sie k&ouml;nnen Ihren Browser so einstellen, dass er keine
                Cookies mehr speichert. Hiermit kann jedoch eine
                Beschr&auml;nkung der zug&auml;nglichen Webseitenfunktionen
                verbunden sein. Zudem wird darauf hingewiesen, dass DoubleClick
                unter Umst&auml;nden auch andere Technologien zur Bildung von
                Nutzerprofilen verwenden kann. Das Abschalten von Cookies bietet
                daher keine Garantie daf&uuml;r, dass keine Nutzerprofile mehr
                erstellt werden.
              </span>
            </p>

            <p>
              <span>
                Weitere Informationen zu Widerspruchsm&ouml;glichkeiten gegen
                die von Google eingeblendeten Werbeanzeigen entnehmen Sie
                folgenden Links: http://policies.google.com/technologies/ads und
                http://adssettings.google.com/authenticated.
              </span>
            </p>

            <p>Facebook Pixel</p>
            <p>
              <span>
                Diese Website nutzt zur Konversionsmessung das
                Besucheraktions-Pixel von Facebook. Anbieter dieses Dienstes ist
                die Facebook Ireland Limited, 4 Grand Canal Square, Dublin 2,
                Irland. Die erfassten Daten werden nach Aussage von Facebook
                jedoch auch in die USA und in andere Drittl&auml;nder
                &uuml;bertragen.
              </span>
            </p>

            <p>
              <span>
                So kann das Verhalten der Seitenbesucher nachverfolgt werden,
                nachdem diese durch Klick auf eine Facebook-Werbeanzeige auf die
                Website des Anbieters weitergeleitet wurden. Dadurch k&ouml;nnen
                die Wirksamkeit der Facebook-Werbeanzeigen f&uuml;r statistische
                und Marktforschungszwecke ausgewertet werden und zuk&uuml;nftige
                Werbema&szlig;nahmen optimiert werden.
              </span>
            </p>

            <p>
              <span>
                Die erhobenen Daten sind f&uuml;r uns als Betreiber dieser
                Website anonym, wir k&ouml;nnen keine R&uuml;ckschl&uuml;sse auf
                die Identit&auml;t der Nutzer ziehen. Die Daten werden aber von
                Facebook gespeichert und verarbeitet, sodass eine Verbindung zum
                jeweiligen Nutzerprofil m&ouml;glich ist und Facebook die Daten
                f&uuml;r eigene Werbezwecke, entsprechend der
                Facebook-Datenverwendungsrichtlinie verwenden kann. Dadurch kann
                Facebook das Schalten von Werbeanzeigen auf Seiten von Facebook
                sowie au&szlig;erhalb von Facebook erm&ouml;glichen. Diese
                Verwendung der Daten kann von uns als Seitenbetreiber nicht
                beeinflusst werden.
              </span>
            </p>

            <p>
              <span>
                Die Nutzung von Facebook-Pixel erfolgt auf Grundlage von Art. 6
                Abs. 1 lit. f DSGVO. Der Websitebetreiber hat ein berechtigtes
                Interesse an effektiven Werbema&szlig;nahmen unter Einschluss
                der sozialen Medien. Sofern eine entsprechende Einwilligung
                abgefragt wurde (z. B. eine Einwilligung zur Speicherung von
                Cookies), erfolgt die Verarbeitung ausschlie&szlig;lich auf
                Grundlage von Art. 6 Abs. 1 lit. a DSGVO; die Einwilligung ist
                jederzeit widerrufbar.
              </span>
            </p>

            <p>
              <span>
                In den Datenschutzhinweisen von Facebook finden Sie weitere
                Hinweise zum Schutz Ihrer Privatsph&auml;re:
                http://de-de.facebook.com/about/privacy/.
              </span>
            </p>

            <p>
              <span>
                Sie k&ouml;nnen au&szlig;erdem die Remarketing-Funktion
                &bdquo;Custom Audiences&ldquo; im Bereich Einstellungen f&uuml;r
                Werbeanzeigen unter
                http://www.facebook.com/ads/preferences/?entry_product=ad_settings_screen
                deaktivieren. Dazu m&uuml;ssen Sie bei Facebook angemeldet sein.
              </span>
            </p>

            <p>
              <span>
                Wenn Sie kein Facebook Konto besitzen, k&ouml;nnen Sie
                nutzungsbasierte Werbung von Facebook auf der Website der
                European Interactive Digital Advertising Alliance deaktivieren:
                http://www.youronlinechoices.com/de/praferenzmanagement/.
              </span>
            </p>

            <p>7. Plugins und Tools</p>
            <p>
              <span>YouTube mit erweitertem Datenschutz</span>
            </p>
            <p>
              <span>
                Diese Website bindet Videos der YouTube ein. Betreiber der
                Seiten ist die Google Ireland Limited (&bdquo;Google&ldquo;),
                Gordon House, Barrow Street, Dublin 4, Irland.
              </span>
            </p>

            <p>
              <span>
                Wir nutzen YouTube im erweiterten Datenschutzmodus. Dieser Modus
                bewirkt laut YouTube, dass YouTube keine Informationen &uuml;ber
                die Besucher auf dieser Website speichert, bevor diese sich das
                Video ansehen. Die Weitergabe von Daten an YouTube-Partner wird
                durch den erweiterten Datenschutzmodus hingegen nicht zwingend
                ausgeschlossen. So stellt YouTube &ndash; unabh&auml;ngig davon,
                ob Sie sich ein Video ansehen &ndash; eine Verbindung zum Google
                DoubleClick-Netzwerk her.
              </span>
            </p>

            <p>
              <span>
                Sobald Sie ein YouTube-Video auf dieser Website starten, wird
                eine Verbindung zu den Servern von YouTube hergestellt. Dabei
                wird dem YouTube-Server mitgeteilt, welche unserer Seiten Sie
                besucht haben. Wenn Sie in Ihrem YouTube-Account eingeloggt
                sind, erm&ouml;glichen Sie YouTube, Ihr Surfverhalten direkt
                Ihrem pers&ouml;nlichen Profil zuzuordnen. Dies k&ouml;nnen Sie
                verhindern, indem Sie sich aus Ihrem YouTube-Account ausloggen.
              </span>
            </p>

            <p>
              <span>
                Des Weiteren kann YouTube nach Starten eines Videos verschiedene
                Cookies auf Ihrem Endger&auml;t speichern. Mit Hilfe dieser
                Cookies kann YouTube Informationen &uuml;ber Besucher dieser
                Website erhalten. Diese Informationen werden u. a. verwendet, um
                Videostatistiken zu erfassen, die Anwenderfreundlichkeit zu
                verbessern und Betrugsversuchen vorzubeugen. Die Cookies
                verbleiben auf Ihrem Endger&auml;t, bis Sie sie l&ouml;schen.
              </span>
            </p>

            <p>
              <span>
                Gegebenenfalls k&ouml;nnen nach dem Start eines YouTube-Videos
                weitere Datenverarbeitungsvorg&auml;nge ausgel&ouml;st werden,
                auf die wir keinen Einfluss haben.
              </span>
            </p>

            <p>
              <span>
                Die Nutzung von YouTube erfolgt im Interesse einer ansprechenden
                Darstellung unserer Online-Angebote. Dies stellt ein
                berechtigtes Interesse im Sinne von Art. 6 Abs. 1 lit. f DSGVO
                dar. Sofern eine entsprechende Einwilligung abgefragt wurde (z.
                B. eine Einwilligung zur Speicherung von Cookies), erfolgt die
                Verarbeitung ausschlie&szlig;lich auf Grundlage von Art. 6 Abs.
                1 lit. a DSGVO; die Einwilligung ist jederzeit widerrufbar.
              </span>
            </p>

            <p>
              <span>
                Weitere Informationen &uuml;ber Datenschutz bei YouTube finden
                Sie in deren Datenschutzerkl&auml;rung unter:
                http://policies.google.com/privacy?hl=de.
              </span>
            </p>

            <p>Google Web Fonts</p>
            <p>
              <span>
                Diese Seite nutzt zur einheitlichen Darstellung von Schriftarten
                so genannte Web Fonts, die von Google bereitgestellt werden. Die
                Google Fonts sind lokal installiert. Eine Verbindung zu Servern
                von Google findet dabei nicht statt.
              </span>
            </p>

            <p>
              <span>
                Weitere Informationen zu Google Web Fonts finden Sie unter
                http://developers.google.com/fonts/faq und in der
                Datenschutzerkl&auml;rung von Google:
                http://policies.google.com/privacy?hl=de.
              </span>
            </p>

            <p>Adobe Fonts</p>
            <p>
              <span>
                Diese Website nutzt zur einheitlichen Darstellung bestimmter
                Schriftarten Web Fonts von Adobe. Anbieter ist die Adobe Systems
                Incorporated, 345 Park Avenue, San Jose, CA 95110-2704, USA
                (Adobe).
              </span>
            </p>

            <p>
              <span>
                Beim Aufruf dieser Website l&auml;dt Ihr Browser die
                ben&ouml;tigten Schriftarten direkt von Adobe, um sie Ihrem
                Endger&auml;t korrekt anzeigen zu k&ouml;nnen. Dabei stellt Ihr
                Browser eine Verbindung zu den Servern von Adobe in den USA her.
                Hierdurch erlangt Adobe Kenntnis dar&uuml;ber, dass &uuml;ber
                Ihre IP-Adresse diese Website aufgerufen wurde. Bei der
                Bereitstellung der Schriftarten werden nach Aussage von Adobe
                keine Cookies gespeichert.
              </span>
            </p>

            <p>
              <span>
                Adobe verf&uuml;gt &uuml;ber eine Zertifizierung nach dem
                EU-US-Privacy-Shield. Der Privacy-Shield ist ein Abkommen
                zwischen den Vereinigten Staaten von Amerika und der
                Europ&auml;ischen Union, das die Einhaltung europ&auml;ischer
                Datenschutzstandards gew&auml;hrleisten soll. N&auml;here
                Informationen finden Sie unter:
                http://www.adobe.com/de/privacy/eudatatransfers.html.
              </span>
            </p>

            <p>
              <span>
                Die Speicherung und Analyse der Daten erfolgt auf Grundlage von
                Art. 6 Abs. 1 lit. f DSGVO. Der Webseitenbetreiber hat ein
                berechtigtes Interesse an der einheitlichen Darstellung des
                Schriftbildes auf seiner Webseite. Sofern eine entsprechende
                Einwilligung abgefragt wurde (z. B. eine Einwilligung zur
                Speicherung von Cookies), erfolgt die Verarbeitung
                ausschlie&szlig;lich auf Grundlage von Art. 6 Abs. 1 lit. a
                DSGVO; die Einwilligung ist jederzeit widerrufbar.
              </span>
            </p>

            <p>
              <span>
                N&auml;here Informationen zu Adobe Fonts erhalten Sie unter:
                http://www.adobe.com/de/privacy/policies/adobe-fonts.html.
              </span>
            </p>

            <p>
              <span>
                Die Datenschutzerkl&auml;rung von Adobe finden Sie unter:
                http://www.adobe.com/de/privacy/policy.html
              </span>
            </p>

            <p>Google Maps (mit Einwilligung)</p>
            <p>
              <span>
                Diese Website nutzt &uuml;ber eine API den Kartendienst Google
                Maps. Anbieterin ist die Google Ireland Limited
                (&bdquo;Google&ldquo;), Gordon House, Barrow Street, Dublin 4,
                Irland.
              </span>
            </p>

            <p>
              <span>
                Um den Datenschutz auf dieser Website zu gew&auml;hrleisten, ist
                Google Maps deaktiviert, wenn Sie diese Website das erste Mal
                betreten. Eine direkte Verbindung zu den Servern von Google wird
                erst hergestellt, wenn Sie Google Maps selbstst&auml;ndig
                aktivieren (Einwilligung nach Art. 6 Abs. 1 lit. a DSGVO). Auf
                diese Weise wird verhindert, dass Ihre Daten schon beim ersten
                Betreten der Seite an Google &uuml;bertragen werden.
              </span>
            </p>

            <p>
              <span>
                Nach der Aktivierung wird Google Maps Ihre IP-Adresse speichern.
                Diese wird anschlie&szlig;end in der Regel an einen Server von
                Google in den USA &uuml;bertragen und dort gespeichert. Der
                Anbieter dieser Seite hat nach der Aktivierung von Google Maps
                keinen Einfluss auf diese Daten&uuml;bertragung.
              </span>
            </p>

            <p>
              <span>
                Mehr Informationen zum Umgang mit Nutzerdaten finden Sie in der
                Datenschutzerkl&auml;rung von Google:
                http://www.google.de/intl/de/policies/privacy/.
              </span>
            </p>

            <p>SoundCloud</p>
            <p>
              <span>
                Auf dieser Website k&ouml;nnen Plugins des sozialen Netzwerks
                SoundCloud (SoundCloud Limited, Berners House, 47-48 Berners
                Street, London W1T 3NF, Gro&szlig;britannien.) integriert sein.
                Die SoundCloud-Plugins erkennen Sie an dem SoundCloud-Logo auf
                den betroffenen Seiten.
              </span>
            </p>

            <p>
              <span>
                Wenn Sie diese Website besuchen, wird nach Aktivierung des
                Plugin eine direkte Verbindung zwischen Ihrem Browser und dem
                SoundCloud-Server hergestellt. SoundCloud erh&auml;lt dadurch
                die Information, dass Sie mit Ihrer IP-Adresse diese Website
                besucht haben. Wenn Sie den &bdquo;Like-Button&ldquo; oder
                &bdquo;Share-Button&ldquo; anklicken w&auml;hrend Sie in Ihrem
                SoundCloud- Benutzerkonto eingeloggt sind, k&ouml;nnen Sie die
                Inhalte dieser Website mit Ihrem SoundCloud-Profil verlinken
                und/oder teilen. Dadurch kann SoundCloud Ihrem Benutzerkonto den
                Besuch dieser Website zuordnen. Wir weisen darauf hin, dass wir
                als Anbieter der Seiten keine Kenntnis vom Inhalt der
                &uuml;bermittelten Daten sowie deren Nutzung durch SoundCloud
                erhalten.
              </span>
            </p>

            <p>
              <span>
                Die Speicherung und Analyse der Daten erfolgt auf Grundlage von
                Art. 6 Abs. 1 lit. f DSGVO. Der Websitebetreiber hat ein
                berechtigtes Interesse an einer m&ouml;glichst umfangreichen
                Sichtbarkeit in den Sozialen Medien. Sofern eine entsprechende
                Einwilligung abgefragt wurde (z. B. eine Einwilligung zur
                Speicherung von Cookies), erfolgt die Verarbeitung
                ausschlie&szlig;lich auf Grundlage von Art. 6 Abs. 1 lit. a
                DSGVO; die Einwilligung ist jederzeit widerrufbar.
              </span>
            </p>

            <p>
              <span>
                Weitere Informationen hierzu finden Sie in der
                Datenschutzerkl&auml;rung von SoundCloud unter:
                http://soundcloud.com/pages/privacy.
              </span>
            </p>

            <p>
              <span>
                Wenn Sie nicht w&uuml;nschen, dass SoundCloud den Besuch dieser
                Website Ihrem SoundCloud- Benutzerkonto zuordnet, loggen Sie
                sich bitte aus Ihrem SoundCloud-Benutzerkonto aus bevor Sie
                Inhalte des SoundCloud-Plugins aktivieren.
              </span>
            </p>

            <p>Spotify</p>
            <p>
              <span>
                Auf dieser Website sind Funktionen des Musik-Dienstes Spotify
                eingebunden. Anbieter ist die Spotify AB, Birger Jarlsgatan 61,
                113 56 Stockholm in Schweden. Die Spotify Plugins erkennen Sie
                an dem gr&uuml;nen Logo auf dieser Website. Eine &Uuml;bersicht
                &uuml;ber die Spotify-Plugins finden Sie unter:
                http://developer.spotify.com.
              </span>
            </p>

            <p>
              <span>
                Dadurch kann beim Besuch dieser Website &uuml;ber das Plugin
                eine direkte Verbindung zwischen Ihrem Browser und dem
                Spotify-Server hergestellt werden. Spotify erh&auml;lt dadurch
                die Information, dass Sie mit Ihrer IP-Adresse diese Website
                besucht haben. Wenn Sie den Spotify Button anklicken
                w&auml;hrend Sie in Ihrem Spotify-Account eingeloggt sind,
                k&ouml;nnen Sie die Inhalte dieser Website auf Ihrem Spotify
                Profil verlinken. Dadurch kann Spotify den Besuch dieser Website
                Ihrem Benutzerkonto zuordnen.
              </span>
            </p>

            <p>
              <span>
                Die Speicherung und Analyse der Daten erfolgt auf Grundlage von
                Art. 6 Abs. 1 lit. f DSGVO. Der Webseitenbetreiber hat ein
                berechtigtes Interesse an der ansprechenden akustischen
                Ausgestaltung seiner Webseite. Sofern eine entsprechende
                Einwilligung abgefragt wurde (z. B. eine Einwilligung zur
                Speicherung von Cookies), erfolgt die Verarbeitung
                ausschlie&szlig;lich auf Grundlage von Art. 6 Abs. 1 lit. a
                DSGVO; die Einwilligung ist jederzeit widerrufbar.
              </span>
            </p>

            <p>
              <span>
                Weitere Informationen hierzu finden Sie in der
                Datenschutzerkl&auml;rung von Spotify:
                http://www.spotify.com/de/legal/privacy-policy/.
              </span>
            </p>

            <p>
              <span>
                Wenn Sie nicht w&uuml;nschen, dass Spotify den Besuch dieser
                Website Ihrem Spotify-Nutzerkonto zuordnen kann, loggen Sie sich
                bitte aus Ihrem Spotify-Benutzerkonto aus.
              </span>
            </p>

            <p>8. eCommerce und Zahlungsanbieter</p>
            <p>
              <span>Verarbeiten von Daten (Kunden- und Vertragsdaten)</span>
            </p>
            <p>
              <span>
                Wir erheben, verarbeiten und nutzen personenbezogene Daten nur,
                soweit sie f&uuml;r die Begr&uuml;ndung, inhaltliche
                Ausgestaltung oder &Auml;nderung des Rechtsverh&auml;ltnisses
                erforderlich sind (Bestandsdaten). Dies erfolgt auf Grundlage
                von Art. 6 Abs. 1 lit. b DSGVO, der die Verarbeitung von Daten
                zur Erf&uuml;llung eines Vertrags oder vorvertraglicher
                Ma&szlig;nahmen gestattet. Personenbezogene Daten &uuml;ber die
                Inanspruchnahme dieser Website (Nutzungsdaten) erheben,
                verarbeiten und nutzen wir nur, soweit dies erforderlich ist, um
                dem Nutzer die Inanspruchnahme des Dienstes zu erm&ouml;glichen
                oder abzurechnen.
              </span>
            </p>

            <p>
              <span>
                Die erhobenen Kundendaten werden nach Abschluss des Auftrags
                oder Beendigung der Gesch&auml;ftsbeziehung gel&ouml;scht.
                Gesetzliche Aufbewahrungsfristen bleiben unber&uuml;hrt.
              </span>
            </p>

            <p>9. Eigene Dienste</p>
            <p>Umgang mit Bewerberdaten</p>
            <p>
              <span>
                Wir bieten Ihnen die M&ouml;glichkeit, sich bei uns zu bewerben
                (z. B. per E-Mail, postalisch oder via Online-Bewerberformular).
                Im Folgenden informieren wir Sie &uuml;ber Umfang, Zweck und
                Verwendung Ihrer im Rahmen des Bewerbungsprozesses erhobenen
                personenbezogenen Daten. Wir versichern, dass die Erhebung,
                Verarbeitung und Nutzung Ihrer Daten in &Uuml;bereinstimmung mit
                geltendem Datenschutzrecht und allen weiteren gesetzlichen
                Bestimmungen erfolgt und Ihre Daten streng vertraulich behandelt
                werden.
              </span>
            </p>

            <p>Umfang und Zweck der Datenerhebung</p>

            <p>
              <span>
                Wenn Sie uns eine Bewerbung zukommen lassen, verarbeiten wir
                Ihre damit verbundenen personenbezogenen Daten (z. B. Kontakt-
                und Kommunikationsdaten, Bewerbungsunterlagen, Notizen im Rahmen
                von Bewerbungsgespr&auml;chen etc.), soweit dies zur
                Entscheidung &uuml;ber die Begr&uuml;ndung eines
                Besch&auml;ftigungsverh&auml;ltnisses erforderlich ist.
                Rechtsgrundlage hierf&uuml;r ist &sect; 26 BDSG-neu nach
                deutschem Recht (Anbahnung eines
                Besch&auml;ftigungsverh&auml;ltnisses), Art. 6 Abs. 1 lit. b
                DSGVO (allgemeine Vertragsanbahnung) und &ndash; sofern Sie eine
                Einwilligung erteilt haben &ndash; Art. 6 Abs. 1 lit. a DSGVO.
                Die Einwilligung ist jederzeit widerrufbar. Ihre
                personenbezogenen Daten werden innerhalb unseres Unternehmens
                ausschlie&szlig;lich an Personen weitergegeben, die an der
                Bearbeitung Ihrer Bewerbung beteiligt sind.
              </span>
            </p>

            <p>
              <span>
                Sofern die Bewerbung erfolgreich ist, werden die von Ihnen
                eingereichten Daten auf Grundlage von &sect; 26 BDSG-neu und
                Art. 6 Abs. 1 lit. b DSGVO zum Zwecke der Durchf&uuml;hrung des
                Besch&auml;ftigungsverh&auml;ltnisses in unseren
                Datenverarbeitungssystemen gespeichert.
              </span>
            </p>

            <p>Aufbewahrungsdauer der Daten</p>

            <p>
              <span>
                Sofern wir Ihnen kein Stellenangebot machen k&ouml;nnen, Sie ein
                Stellenangebot ablehnen oder Ihre Bewerbung zur&uuml;ckziehen,
                behalten wir uns das Recht vor, die von Ihnen &uuml;bermittelten
                Daten auf Grundlage unserer berechtigten Interessen (Art. 6 Abs.
                1 lit. f DSGVO) bis zu 6 Monate ab der Beendigung des
                Bewerbungsverfahrens (Ablehnung oder Zur&uuml;ckziehung der
                Bewerbung) bei uns aufzubewahren. Anschlie&szlig;end werden die
                Daten gel&ouml;scht und die physischen Bewerbungsunterlagen
                vernichtet. Die Aufbewahrung dient insbesondere Nachweiszwecken
                im Falle eines Rechtsstreits. Sofern ersichtlich ist, dass die
                Daten nach Ablauf der 6-Monatsfrist erforderlich sein werden
                (z.B. aufgrund eines drohenden oder anh&auml;ngigen
                Rechtsstreits), findet eine L&ouml;schung erst statt, wenn der
                Zweck f&uuml;r die weitergehende Aufbewahrung entf&auml;llt.
              </span>
            </p>

            <p>
              <span>
                Eine l&auml;ngere Aufbewahrung kann au&szlig;erdem stattfinden,
                wenn Sie eine entsprechende Einwilligung (Art. 6 Abs. 1 lit. a
                DSGVO) erteilt haben oder wenn gesetzliche
                Aufbewahrungspflichten der L&ouml;schung entgegenstehen.
              </span>
            </p>

            <p>Aufnahme in den Bewerber-Pool</p>

            <p>
              <span>
                Sofern wir Ihnen kein Stellenangebot machen, besteht ggf. die
                M&ouml;glichkeit, Sie in unseren Bewerber-Pool aufzunehmen. Im
                Falle der Aufnahme werden alle Dokumente und Angaben aus der
                Bewerbung in den Bewerber-Pool &uuml;bernommen, um Sie im Falle
                von passenden Vakanzen zu kontaktieren.
              </span>
            </p>

            <p>
              <span>
                Die Aufnahme in den Bewerber-Pool geschieht ausschlie&szlig;lich
                auf Grundlage Ihrer ausdr&uuml;cklichen Einwilligung (Art. 6
                Abs. 1 lit. a DSGVO). Die Abgabe der Einwilligung ist freiwillig
                und steht in keinem Bezug zum laufenden Bewerbungsverfahren. Der
                Betroffene kann seine Einwilligung jederzeit widerrufen. In
                diesem Falle werden die Daten aus dem Bewerber-Pool
                unwiderruflich gel&ouml;scht, sofern keine gesetzlichen
                Aufbewahrungsgr&uuml;nde vorliegen.
              </span>
            </p>

            <p>
              <span>
                Die Daten aus dem Bewerber-Pool werden sp&auml;testens zwei
                Jahre nach Erteilung der Einwilligung unwiderruflich
                gel&ouml;scht.
              </span>
            </p>
          </div>
        </div>
      </PageSection>
    </Layout>
  )
}

export default Datenschutz
